<template>
  <section class="max-w-screen-xl">
    <div
      v-if="error != 0"
      class="pointer-events-none fixed inset-x-0 bottom-0 sm:px-6 sm:pb-5 lg:px-8"
    >
      <div
        class="pointer-events-auto flex items-center justify-between gap-x-6 bg-gray-900 px-6 py-2.5 sm:rounded-xl sm:py-3 sm:pl-4 sm:pr-3.5"
      >
        <p class="text-sm leading-6 text-white">
          <strong class="font-semibold">Fehler</strong
          ><svg
            viewBox="0 0 2 2"
            class="mx-2 inline h-0.5 w-0.5 fill-current"
            aria-hidden="true"
          >
            <circle cx="1" cy="1" r="1" /></svg
          >Session ID not found<span aria-hidden="true">&rarr;</span>
        </p>
        <button
          type="button"
          @click="error = 0"
          class="-m-3 flex-none p-3 focus-visible:outline-offset-[-4px]"
        >
          <span class="sr-only">Dismiss</span>
          <svg
            class="h-5 w-5 text-white"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"
            />
          </svg>
        </button>
      </div>
    </div>
    <!-- This example requires Tailwind CSS v2.0+ -->
    <div
      class="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8"
    >
      <div class="absolute inset-0">
        <div class="bg-white h-1/3 sm:h-2/3"></div>
      </div>
      <div class="relative max-w-7xl mx-auto">
        <div class="text-center">
          <h2
            class="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl"
          >
            No active configurations
          </h2>
          <p class="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
            You have no active configurations. <br />
            To start a new one select one of the following steps.
          </p>
        </div>
        <div
          class="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none"
        >
          <div class="flex flex-col rounded-lg shadow-lg overflow-hidden">
            <div class="flex-shrink-0">
              <img
                class="h-48 w-full object-cover"
                src="https://images.unsplash.com/photo-1547586696-ea22b4d4235d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80"
                alt=""
              />
            </div>
            <div class="flex-1 bg-white p-6 flex flex-col justify-between">
              <div class="flex-1">
                <p class="text-sm font-medium text-indigo-600">
                  <a href="#" class="hover:underline"> Saved configuration </a>
                </p>
                <a href="#" class="block mt-2">
                  <p class="text-xl font-semibold text-gray-900">
                    Open a saved configuration
                  </p>
                  <p class="mt-3 text-base text-gray-500 h-32">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Velit facilis asperiores porro quaerat doloribus, eveniet
                    dolore.
                  </p>
                  <div class="mt-4 flex">
                    <div class="flex-grow">
                      <input
                        type="text"
                        name="session_id"
                        v-model="sessionId"
                        class="block w-full shadow-sm focus:ring-sky-500 focus:border-sky-500 sm:text-sm border-gray-300 rounded-md"
                        placeholder="configuration id"
                        aria-describedby="add-team-members-helper"
                      />
                    </div>
                    <span class="ml-3">
                      <button
                        type="button"
                        @click="loadSession"
                        class="bg-white inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                      >
                        <!-- Heroicon name: solid/plus -->
                        <svg
                          class="-ml-2 mr-1 h-5 w-5 text-gray-400"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        <span>open</span>
                      </button>
                    </span>
                  </div>
                </a>
              </div>
            </div>
          </div>

          <div class="flex flex-col rounded-lg shadow-lg overflow-hidden">
            <div class="flex-shrink-0">
              <img
                class="h-48 w-full object-cover"
                src="@/assets/img/empty/product_catalogs@0.5x.jpg"
                srcset="@/assets/img/empty/product_catalogs@1x.jpg"
                alt=""
              />
            </div>
            <div class="flex-1 bg-white p-6 flex flex-col justify-between">
              <div class="flex-1">
                <p class="text-sm font-medium text-indigo-600">
                  <a href="#" class="hover:underline"> Catalogs </a>
                </p>
                <a href="#" class="block mt-2">
                  <p class="text-xl font-semibold text-gray-900">
                    Select item from a catalog
                  </p>
                  <p class="mt-3 text-base text-gray-500 h-32">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Architecto accusantium praesentium eius, ut atque fuga
                    culpa, similique sequi cum eos quis dolorum.
                  </p>

                  <div class="mt-4 flex">
                    <div class="flex-grow">
                      <input
                        type="text"
                        name="item_number"
                        v-model="itemNumber"
                        class="block w-full shadow-sm focus:ring-sky-500 focus:border-sky-500 sm:text-sm border-gray-300 rounded-md"
                        placeholder="item number"
                        aria-describedby="add-team-members-helper"
                      />
                    </div>
                    <span class="ml-3">
                      <button
                        type="button"
                        @click="createSession(itemNumber)"
                        class="bg-white inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                      >
                        <!-- Heroicon name: solid/plus -->
                        <svg
                          class="-ml-2 mr-1 h-5 w-5 text-gray-400"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        <span>open</span>
                      </button>
                    </span>
                  </div>
                </a>
              </div>
            </div>
          </div>

          <div class="flex flex-col rounded-lg shadow-lg overflow-hidden">
            <div class="flex-shrink-0">
              <img
                class="h-48 w-full object-cover"
                src="@/assets/img/empty/blank_ring@0.5x.jpg"
                srcset="@/assets/img/empty/blank_ring@1x.jpg"
                alt=""
              />
            </div>
            <div class="flex-1 bg-white p-6 flex flex-col justify-between">
              <div class="flex-1">
                <p class="text-sm font-medium text-indigo-600">Default ring</p>
                <a @click="createSession" class="block mt-2">
                  <p class="text-xl font-semibold text-gray-900">
                    Start with a blank ring
                  </p>
                  <p class="mt-3 text-base text-gray-500 h-32">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Sint harum rerum voluptatem quo recusandae magni placeat
                    saepe molestiae, sed excepturi cumque corporis perferendis
                    hic.
                  </p>

                  <button
                    type="button"
                    class="mt-4 w-full bg-white items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                  >
                    <span>Start configuration</span>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { Sessions } from "@/assets/ts/sessions";
import { useStore } from "vuex";
import router from "@/router";

export default defineComponent({
  name: "ConfInit",
  components: {},
  setup() {
    const store = useStore();
    const sessionId = ref("");
    const error = ref(0);
    const itemNumber = ref("");

    const createSession = (newItemNumber = "RU-1588-1") => {
      if (newItemNumber === "") {
        error.value = 2;
      } else {
        store.commit("loading/actionStarted");

        const sessionsObj: Sessions = new Sessions();

        sessionsObj.create(newItemNumber).then((newSessionId: any) => {
          store.commit("loading/actionFinished");
          if (newSessionId !== "") {
            router.push({
              name: "ConfigurationOverview",
              params: {
                sessionId: newSessionId,
              },
            });
          }
        });
      }
    };

    const loadSession = () => {
      store.commit("loading/actionStarted");

      const sessionsObj: Sessions = new Sessions();

      sessionsObj.get(sessionId.value).then((sessionData: any) => {
        console.log(sessionData);
        store.commit("loading/actionFinished");
        if (typeof sessionData.error != "undefined") {
          error.value = sessionData.error;
        } else {
          store.commit("session/setFull", sessionData);
          router.push({
            name: "ConfigurationOverview",
            params: {
              sessionId: sessionId.value,
            },
          });
        }
      });
    };

    return { createSession, loadSession, sessionId, error, itemNumber };
  },
});
</script>

<style></style>
