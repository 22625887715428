<template>
  <div v-if="session.items[0]" class="md:grid sm:grid-cols-3 w-screen relative">
    <item-v2-image-grid class="col-span-2 sticky z-50 top-0 md:relative bg-white h-96 md:h-auto" :image="session.items[0].image" />
    <div class="md:px-8 space-y-4 md:pt-0 z-10">
      <div class="px-4">
        <h1
          class="text-2xl mt-4 font-bold tracking-tight text-gray-900 sm:text-3xl"
        >
          {{ session.items[0].desc.title }}
        </h1>
      </div>
      <div class="px-4">
        <p class="text-2xl tracking-tight text-gray-900 mb-2">
          {{ $n(session.items[0].price.total, "currency") }}
        </p>
      </div>
      <item-v2-variants
        :item="session.items[0]"
        @patch="(event) => patch(event.action, event.value)"
      />
      <div class="my-4 space-y-4 w-full px-2">
          <a href="/partner" class="block w-full text-white bg-brand-main p-2 text-center hover:bg-white hover:text-brand-main border border-brand-main">Im Standort beraten lassen</a>
        <form
            method="post"
            action="https://www.rubin-ec84.de:7004/store/faces/login.xhtml"
            target="_blank"
            ref="orderForm"
        >
          <button
              type="button"
              @click="createOrder()"
              class="block w-full bg-white text-brand-main p-2 text-center hover:bg-white hover:text-brand-main border border-brand-main"
          >
            Bestellen
          </button>
          <input type="hidden" name="lang" value="de" />
          <input type="hidden" name="action" value="order" />
          <input type="hidden" name="order" :value="orderData" />
        </form>
      </div>
      <div v-if="false">USPs</div>
      <div class="p-4">
        <p class="text-sm leading-6 mb-2">
          {{ session.items[0].desc.description }} Lorem ipsum dolor sit amet,
          consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
          labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos
          et accusam et justo duo dolores et ea rebum. Stet clita kasd
          gubergren,
        </p>
        <description-parts :parts-list="session.items[0].desc.parts" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { Sessions } from "@/assets/ts/sessions";
import DescriptionParts from "@/components/description/descriptionParts.vue";
import ItemV2ImageGrid from "@/components/itemV2/itemV2ImageGrid.vue";
import ItemV2Variants from "@/components/itemV2/itemV2Variants.vue";

const store = useStore();
const route = useRoute();
const error = ref(0);

const session = ref(store.getters["session/get"]);

const sessionsObj: Sessions = new Sessions();

if (session.value.session_id !== route.params.sessionId) {
  store.commit("loading/actionStarted");

  sessionsObj
    .get(route.params.sessionId.toString())
    .then((sessionData: any) => {
      store.commit("loading/actionFinished");
      if (typeof sessionData.error != "undefined") {
        error.value = sessionData.error;
      } else {
        store.commit("session/setFull", sessionData);
        session.value = store.getters["session/get"];
      }
    });
}

/**
 * order data
 */

const orderData = ref("")
const orderForm = ref(null)

const createOrder = () => {
  store.commit("loading/actionStarted");

  sessionsObj
      .get_order_data(session.value.session_id)
      .then((tmpOrderData: any) => {
        if (typeof tmpOrderData.error != "undefined") {
          error.value = tmpOrderData.error;
        } else {

          orderData.value = btoa(JSON.stringify(tmpOrderData));

          setTimeout(() => {
            orderForm.value?.submit()
          }, 100)
          store.commit("loading/actionFinished");
        }
      });
}

const patch = (action: string, value: any) => {
  store.commit("loading/actionStarted");
  sessionsObj
    .patch(session.value.session_id, 0, action, value)
    .then((sessionData: any) => {
      store.commit("loading/actionFinished");
      if (typeof sessionData.error != "undefined") {
        error.value = sessionData.error;
      } else {
        store.commit("session/setFull", sessionData);
        session.value = store.getters["session/get"];
      }
    });
};

const currentColor = computed(() => {
  // search on root level
  for (let variantIdx in session.value.items[0].variants) {
    if (session.value.items[0].variants[variantIdx].idx === "color") {
      const variantData = session.value.items[0].variants[variantIdx];
      for (let valueIdx in variantData.values) {
        if (variantData.values[valueIdx].match === true) {
          return variantData.values[valueIdx].idx.toLowerCase();
        }
      }
    }
  }
  // search alternativ
  for (let variantIdx in session.value.items[0].variants) {
    if (session.value.items[0].variants[variantIdx].idx.includes("color")) {
      const variantData = session.value.items[0].variants[variantIdx];
      for (let valueIdx in variantData.values) {
        if (variantData.values[valueIdx].match === true) {
          return variantData.values[valueIdx].idx.toLowerCase();
        }
      }
    }
  }
  return "";
});
</script>
