<template>
  <section class="w-full md mx-auto bg-white">
    <div @click="startAnimation">
      <div
        v-for="imageIdx in images"
        :key="imageIdx"
        :id="'animation-' + imageIdx"
        :class="{
          block: parseInt(imageIdx) === curImageIdx,
          hidden: parseInt(imageIdx) !== curImageIdx,
        }"
      >
        <item-image
          :image-url="getFinalAnimationImageUrl(curImageIdx)"
          :image-layer-url="getFinalAnimationImageLayerUrl(curImageIdx)"
        ></item-image>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";
import { defineProps } from "vue/dist/vue";
import ItemImage from "@/components/itemImage.vue";

const props = defineProps(["imageUrl", "imageLayerUrl"]);

const imageFirstId = 1;
const imageLastId = 30;
const curImageIdx = ref(imageFirstId);

const getFinalAnimationImageUrl = (imageIdx: number) => {
  return generateAnimationUrl(props.imageUrl, "item", imageIdx)
};

const getFinalAnimationImageLayerUrl = (imageIdx: number) => {
  return generateAnimationUrl(props.imageLayerUrl, "layer", imageIdx)
};

const generateAnimationUrl = (baseImage: string, layerType: string, imageIdx: number) => {
  const urlParts = baseImage.replace("/conf/", "/animation/").split("/")
  urlParts[urlParts.length -1] = layerType + "/ani-" + urlParts[urlParts.length -1]
  return urlParts.join("/").replace(".webp", "-" + String(imageIdx).padStart(4, "0") + ".webp");
}

const images = computed(() => {
  const imageList: string[] = [];

  for (let imageIdx = imageFirstId; imageIdx <= imageLastId; imageIdx++) {
    imageList.push(imageIdx.toString().padStart(3, "0"));
  }

  return imageList;
});

const setCurrentImageIdx = () => {
  if (curImageIdx.value >= imageLastId) {
    curImageIdx.value = imageFirstId;
    clearInterval(intervalId.value);
  } else {
    curImageIdx.value++;
  }
  console.log(curImageIdx.value);
};

const intervalId = ref();

const startAnimation = () => {
  curImageIdx.value = imageFirstId;
  intervalId.value = setInterval(setCurrentImageIdx, 150);
};
</script>

<style></style>
